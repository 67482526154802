import type { UseFieldArrayRemove } from "react-hook-form";
import { Controller } from "react-hook-form";
import { X } from "lucide-react";
import { Checkbox } from "@/components/checkbox";
import { GreaterAffixCheckbox as AffixCheckbox } from "@/components/greater-affix-checkbox";

export function Affix({
  affixTitle,
  statIndex,
  affixIndex,
  removeAffix,
}: {
  affixTitle: string;
  statIndex: number;
  affixIndex: number;
  removeAffix: UseFieldArrayRemove;
}): JSX.Element {
  return (
    <div className="grid h-[max-content] grid-cols-[2fr_1fr_25px]">
      <div className="flex grid-cols-2 items-center gap-2">
        <AffixEnabledCheckbox affixIndex={affixIndex} statIndex={statIndex} />

        <GreaterAffixEnabledCheckbox
          affixIndex={affixIndex}
          statIndex={statIndex}
        />

        <span className="max-w-[195px] font-sans">{affixTitle}</span>
      </div>
      <div className="grid grow grid-cols-2 items-center justify-center">
        <AffixMinInput affixIndex={affixIndex} statIndex={statIndex} />
        <AffixMaxInput affixIndex={affixIndex} statIndex={statIndex} />
      </div>
      <div className="flex items-center justify-center">
        <RemoveAffixIcon affixIndex={affixIndex} remove={removeAffix} />
      </div>
    </div>
  );
}

function AffixEnabledCheckbox({
  statIndex,
  affixIndex,
}: {
  statIndex: number;
  affixIndex: number;
}): JSX.Element {
  return (
    <Controller
      name={`statFilters.${statIndex}.affixes.${affixIndex}.enabled`}
      render={({ field }) => {
        return (
          <Checkbox
            checked={Boolean(field.value)}
            onCheckedChange={(checked) => {
              field.onChange(checked);
            }}
          />
        );
      }}
    />
  );
}

function GreaterAffixEnabledCheckbox({
  statIndex,
  affixIndex,
}: {
  statIndex: number;
  affixIndex: number;
}): JSX.Element {
  return (
    <Controller
      name={`statFilters.${statIndex}.affixes.${affixIndex}.greaterAffixEnabled`}
      render={({ field }) => {
        return (
          <AffixCheckbox
            checked={Boolean(field.value)}
            className="h-5 w-5"
            onCheckedChange={(checked) => {
              field.onChange(checked);
            }}
          />
        );
      }}
    />
  );
}

function AffixMinInput({
  statIndex,
  affixIndex,
}: {
  statIndex: number;
  affixIndex: number;
}): JSX.Element {
  return (
    <Controller
      name={`statFilters.${statIndex}.affixes.${affixIndex}.min`}
      render={({ field }) => {
        return (
          <input
            {...field}
            className="max-h-7 border border-gray-700 bg-gray-600 px-2 py-[6px] text-center font-sans text-xs text-gray-50 placeholder:text-gray-50 placeholder:opacity-50 focus:outline-none"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.replace(/[^-?\d]/g, "");
              field.onChange(e);
            }}
            placeholder="MIN"
            value={field.value as string}
          />
        );
      }}
    />
  );
}

function AffixMaxInput({
  statIndex,
  affixIndex,
}: {
  statIndex: number;
  affixIndex: number;
}): JSX.Element {
  return (
    <Controller
      name={`statFilters.${statIndex}.affixes.${affixIndex}.max`}
      render={({ field }) => {
        return (
          <input
            {...field}
            className="max-h-7 border border-gray-700 bg-gray-600 px-2 py-[6px] text-center font-sans text-xs text-gray-50 placeholder:text-gray-50 placeholder:opacity-50 focus:outline-none"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.replace(/[^-?\d]/g, "");
              field.onChange(e);
            }}
            placeholder="MAX"
            value={field.value as string}
          />
        );
      }}
    />
  );
}

function RemoveAffixIcon({
  remove,
  affixIndex,
  className,
}: {
  remove: UseFieldArrayRemove;
  affixIndex: number;
  className?: string;
}): JSX.Element {
  return (
    <button
      className={className}
      onClick={() => {
        remove(affixIndex);
      }}
      type="button"
    >
      <X className="h-3 w-3" strokeWidth={6} />
    </button>
  );
}
